import { CustomizerService } from './shared/services/customizer.service';
import { AuthService } from './auth/auth.service';
import { Component } from '@angular/core';
import { NavService } from './shared/services/nav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'VibroSight Dashboard';
  constructor(public navServices: NavService, public customizer: CustomizerService, public authService: AuthService) { }
}
