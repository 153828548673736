import { Roles } from './roles.model';
import { User } from './user.model';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isLoggedIn = new ReplaySubject<boolean>(1);
  private _isAdmin = new ReplaySubject<boolean>(1);
  private _isUser = new ReplaySubject<boolean>(1);
  private _roleSubscription?: Subscription;

  public user: Observable<User | undefined>;

  get isLoggedIn(): Observable<boolean> {
    return this._isLoggedIn;
  }
  get isAdmin(): Observable<boolean> {
    return this._isAdmin;
  }
  get isUser(): Observable<boolean> {
    return this._isUser;
  }

  constructor(public afAuth: AngularFireAuth, public router: Router, private afStore: AngularFirestore) {
    this.afAuth.authState.subscribe(async (user) => {
      if (this._roleSubscription) {
        this._roleSubscription.unsubscribe();
        this._roleSubscription = undefined;
      }
      if (user) {
        this.user = this.afStore.doc<User>(`profiles/${user.uid}`).valueChanges({ uid: 'Id' });
        this._isLoggedIn.next(true);
        this._roleSubscription = this.afStore
          .collection(`profiles/${user.uid}/roles`)
          .valueChanges({ idField: 'Id' })
          .pipe(
            map((doc) => {
              return doc.map((d) => d.Id);
            })
          )
          .subscribe((rolesArray) => {
            this.updateRoles(rolesArray);
          });
      } else {
        this._isLoggedIn.next(false);
        this.updateRoles(undefined);
        await this.router.navigate(['/auth']);
      }
    });
  }

  async logout() {
    await this.afAuth.signOut();
  }

  private updateRoles(role: string[] | undefined) {
    const isAdmin = role && role.indexOf('admin') > -1;
    const isUser = isAdmin || (role && role.indexOf('user') > -1);
    //console.log(`Updating roles(admin: ${isAdmin}, user: ${isUser})`)
    this._isAdmin.next(isAdmin);
    this._isUser.next(isUser);
  }
}
