import { Quote, QuoteMessage } from './quote.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  constructor(
    private http: HttpClient) { }

  async getQuoteOfTheDay(): Promise<Quote> {
    const quoteMessage = await this.http.get<QuoteMessage>('https://quotes.rest/qod?language=en').toPromise();
    return quoteMessage.contents.quotes[0];

  }
}
