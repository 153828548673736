<div class="dark-body-only">
    <!-- page-wrapper Start-->
    <div class="page-wrapper" [ngClass]="customizer.data.settings.sidebar.type" id="canvas-bookmark">
        <app-header *ngIf="authService.isUser     | async"></app-header>
        <!-- Page Body Start-->
        <div class="page-body-wrapper" [ngClass]="customizer.data.settings.sidebar.body_type">
            <div class="page-sidebar" id="pages-sidebar"
                [class.open]="navServices.collapseSidebar && (authService.isUser | async)"
                [ngClass]="customizer.data.settings.sidebar_backround"
                [attr.sidebar-layout]="this.customizer.data.settings.sidebar_setting"
                *ngIf="authService.isUser  | async">
                <app-sidebar></app-sidebar>
            </div>
            <div class="page-body">

                <main>
                    <router-outlet></router-outlet>
                </main>
            </div>
            <!-- footer start-->
            <footer class="footer">
                <app-footer></app-footer>
            </footer>
            <!-- footer End-->
        </div>
        <!-- Page Body End-->
    </div>
    <!-- page-wrapper End-->
</div>