import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { tap, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    return combineLatest([this.auth.isLoggedIn, this.auth.isAdmin]).pipe(
      take(1),
      map((authInfo) => {
        if (!authInfo[0]) {
          console.error('Access denied - Unauthorized')
          return this.router.parseUrl('/auth/');
        } else if (!authInfo[1]) {
          console.error('Access denied - Admin only')
          return this.router.parseUrl('/auth/unauthorized');
        } else {
          return true;
        }
      })
    );
  }

}
