import { AuthService } from './auth/auth.service';
import { firebase, firebaseui } from 'firebaseui-angular';

export const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'redirect',
  signInOptions: [
    {
      requireDisplayName: true,
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    }
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
  signInSuccessUrl: '/dashboard'
};
