import { ModalModule } from 'ngx-bootstrap/modal';
import { QuotesService } from './services/quotes.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ContentLayoutComponent } from './components/layout/content-layout/content-layout.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule } from '@angular/common/http';
// services
import { NavService } from './services/nav.service';

// Directives
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { ToastrModule } from 'ngx-toastr';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';

@NgModule({
   declarations: [
      HeaderComponent,
      FooterComponent,
      SidebarComponent,
      ContentLayoutComponent,
      FeatherIconsComponent,
      BreadcrumbComponent,
      ToggleFullscreenDirective,
      LoadingIndicatorComponent,
      ConfirmDialogComponent,
   ],
   imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ToastrModule.forRoot(), // ToastrModule added,
      BsDatepickerModule.forRoot(),
      HttpClientModule,
      ModalModule.forRoot()
   ],
   exports: [
      FeatherIconsComponent,
      HeaderComponent,
      FooterComponent,
      SidebarComponent,
      ToggleFullscreenDirective,
      BreadcrumbComponent,
      LoadingIndicatorComponent,
   ],
   providers: [NavService, QuotesService],
})
export class SharedModule {}
