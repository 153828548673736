<div class="container-fluid">
  <div class="page-header">
    <div class="row">
      <div class="col-lg-6">
        <div class="page-header-left">
          <h3>Sample</h3>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/sample/sample-component'">
                <app-feather-icons [icon]="'home'"></app-feather-icons>
              </a></li>
            <li class="breadcrumb-item">Sample</li>
            <li class="breadcrumb-item active">SampleComponent
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>